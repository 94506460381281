<template>
  <v-container class="pa-0" fluid>
    <!-- loader -->
    <div v-if="loading" class="d-flex flex-column text-center py-12">
      <span class="subtext--text">Obtendo Álbuns...</span>

      <loader-content />
    </div>

    <v-container v-else-if="albums.length > 0">
      <!-- grid -->
      <v-row>
        <v-col
          v-for="(item, index) in albums"
          :key="index"
          v-show="item.preview_pictures.length > 0"
          cols="6"
          md="4"
          lg="3"
        >
          <v-card
            class="pa-4 rounded-lg"
            color="surface"
            @click="selectAlbum(item.album)"
          >
            <v-card class="mb-2" tile>
              <v-row no-gutters>
                <v-col
                  v-for="(picture, index) in item.preview_pictures"
                  :key="index"
                  cols="6"
                >
                  <v-img :src="picture.url" height="100%" aspect-ratio="1" />
                </v-col>
              </v-row>
            </v-card>

            <div>
              <h1 class="text-h6">
                {{ item.album.name }}
              </h1>

              <p class="text-subtitle-1 subtext--text mb-0">
                {{ item.album.description }}
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- empty -->
    <v-row v-else class="subtext--text justify-center py-4" no-gutters>
      <span>Nenhum resultado encontrado</span>
    </v-row>
  </v-container>
</template>

<script>
import { getAlbums } from "@/services/gallery";
import { formatDate } from "@/utils";
import MansoryGrid from "@/components/gallery/MansoryGrid";

export default {
  data() {
    return {
      loading: false,
      albums: [],
    };
  },

  components: {
    MansoryGrid,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    // receive albums
    async getData() {
      try {
        this.loading = true;

        await getAlbums().then((res) => {
          this.albums = res.body;
        });
      } catch (err) {
        console.log(err.data);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },

    // select album logistic
    selectAlbum(data) {
      this.$router.push({ path: `/gallery/albums/${data._id}` });
    },

    formatDate,
  },
};
</script>

